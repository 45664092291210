.dialog-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-item {
    margin-bottom: 20px;
    padding: 0px 10px;
    width: fill;
}

.signIn-button {
    background-color: #1C2F36;
}

.forgot{
    padding-top: '50px';
}
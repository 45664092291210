
footer{
  
    display: flex;
    align-items: center;
    height: 100%;
    clear: both;
    margin-bottom: 0;
    background-color: #1C2F36;
    height: 15vw;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 0;
    clear:both;
  }
  section{
    float:left;
    width: 22%;
    justify-content:center;
    color:#ffffff;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    margin:0;
    padding:0;
  }
  .Logo_footer {
    width: 18vw;
    padding:0vw 0vw 0 2vw;
    margin:0;
  }
  
  .mainNavButtons{
    font-size: 1.6vw;
    line-height: 2.5vw;
    padding:0vw 0 0 5vw;
    margin:0;
  
  }
  
  section .fa{
    font-size: 1.9vw;
  }
  .icons{
    font-size: 1.6vw;
    line-height: 2.3vw;
    padding:0 2vw 0 2vw;
    margin: 0vw 0 0 0;
  }
  
  .HQ{
    font-size: 1.5vw;
    line-height: 2vw;
    padding:0vw 0 0 0;
  }
  
  .HQ .padding{
    margin:15px 0 0 0;
  } 

body{
    font-family: 'Roboto';
}

/* --------------------------- Compare Cars Begins --------------------*/

.CompareTable table {
  border-collapse:collapse;
  font-family: 'Roboto';
  border-spacing: 0 10px;
  font-size: 1.4vw;
  width: 90%;
  margin: auto;
  margin-bottom: 3vw;
  
}

.ComparingTable tr {
  border-bottom: 1px solid #1C2F36;
}

.ComparingTable td {
  padding: 20px 20px;
  text-align: center;
  color: #1C2F36;
  width: 33%;
}

.ComparingTable td:first-child {
  text-align: left;
  color: #1C2F36;
  font-weight: 700;
  width: 20%;
  
}

#carSelection{
    /* background-color: red; */
    vertical-align: middle;
}

#carSelection select{
    background-color: #1C2F36;
    color:#A0AEB3;
    border-radius: 5px;
    padding: 3px;
    font-size: 1.4vw;
}

 #firstCar, #secondCar{
  background-color: transparent;

 }
 #firstCar img, #secondCar img{
  background-color: transparent;
  width:100%;
  padding: 20px;
 } 

/* --------------------------- Compare Cars Ends --------------------*/

/* --------------------------- Car Listing Begins --------------------*/
.mainCarSection {
    display: flex;
    background-color: #A0AEB3;
    padding: 20px;
    padding-bottom: 0px;
}
  
.mainImg { 
    display:flex;
    width: 60vw;
    float: right;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}
  
.big-car-info {
    text-align: left;
}
  
.big-car-info h2 {
    position: absolute;
    color: #1C2F36;
    font-family: 'Roboto';
    margin-top: 10px;
    font-size: 6vw;
    margin-left: 1%;
    margin-top:1%
}
.big-car-info .buffer{
    position:static;
    visibility: hidden;
    margin:12px;
}

.mainCarSection button {
    font-family: 'Roboto';
    background-color: #1C2F36; 
    border: none;
    color: white;
    padding: 12px 45px;
    text-align: center;
    margin-left: 9%;
    font-weight: 400;
    font-size: 16px;
    border-radius: 9px;
    cursor: pointer;
}

.POWER{
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #1C2F36;
    width:50%;
    padding-top: 7%;
    padding-bottom: 5%;
    font-family: 'Roboto';
    font-weight: 700;
}
.POWER h2, .FUEL h2{
    color:#A0AEB3;
    font-size: 2.3vw;
    line-height: 36px;
}
.POWER p, .FUEL p{
    color:white;
    font-size: 4vw;
    margin: 35px;
}

.FUEL{
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color:#192A31;
    width:50%;
    padding-top: 7%;
    padding-bottom: 5%;
    font-family: 'Roboto';
    font-weight: 700;
}
.info{
    background-color:#A0AEB3 ;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pictureMain{
    width:50%;
    height: 410px;
    float: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #1C2F36; */
}
.pictureMain:hover .prev, .pictureMain:hover .next {
    display: block;
}
.images{
    width:100%;
    float:left;
    max-height: 100%;
    object-fit: contain;
}

.button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    display: none;
  }
  
.prev {
    left: 0;
}
  
.next {
    right: 0;
}


.aside{
    margin: 0;
    float: right;
    width:50%;
    justify-content: center; 
    background-color:#A0AEB3 ;
}

.aside h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.8vw;
    padding: 4% 7%;
    color: #192A31;
}
.title-band {
    position: relative;
    height: 2vw;
    align-items: center;
  }

.title:before,
.title:after {
  content: "";
  position: absolute;
  top: 1.5vw;
  height: 1vw;
  margin: 0.8vw 0;
  padding: 0;
  background-color:#1C2F36;
}

.title:before {
  left: 0;
  width: 2.2vw;
}
.title:after {
  right: 0;
  width: 30vw;
}

.aside ol{
    padding: 6%;
}
.aside li{
    justify-content: space-between;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 1.6vw;
    line-height: 3vw;
    font-weight: 400;
    padding: 0px 5px;
    list-style-type: none;
    color: white;
}
.aside li strong{
    color:#192A31;
}

/* ------------- Car Listing Ends ---------------- */


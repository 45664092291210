nav {
  background-color: #1C2F36;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
}
nav a {
  float:left;
  color : white;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 2vw;
  padding: 0.7vw 1vw; 
  margin: 4vw 1.8vw;
}
nav .icon{
  /* max-width: 2.5vw; */
  height: 2.6vw;
}



nav a:hover,.icon:hover {
  background-color: rgba(242, 242, 242,0.40);
  color: #f2f2f2;
}


.Logo {
  float:left;
  max-width: 12vw;
  height: 5vw;
  margin: 24px 22px 24px 15px;
  vertical-align: middle;
  line-height: 120px;
}

.icon{
  float: right;
  text-align: center;
  float: right;
  color: white;
  text-align: center;
  margin:3vw 10px;
  padding: 1vw 0.4vw;
  vertical-align: middle;
  line-height: 120px;
}

.dropdown-buttons {
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-buttons button {
    border: none;
    background-color: #e6e6e6;
    color: #000;
    padding: 5px 10px;
    margin: 5px 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .dropdown-buttons button:hover {
    background-color: #ccc;
  }
  a:visited {
    color:#000;
  }
  
  
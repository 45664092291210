/*Table*/
table {
    border-collapse: collapse;
    font-family: 'Roboto';
    border-spacing: 0 10px;
    width: 90%;
    margin: auto;
    margin-bottom: 3vw;
}

tr {

    border-bottom: 1px solid #1C2F36;
}

#top {
    font-weight: bold;
}

td {
    padding: 20px 20px;
    text-align: center;
    color: #1C2F36;
    margin: 0 auto;
    vertical-align: middle;
}

    td:first-child {
        text-align: left;
        color: #1C2F36;
        font-weight: 700;
    }

    .sellButton button{
        background-color: #1C2F36;
        border-radius: 10px;
        border: none;
        padding: 7px 15px;
        float:right;
        margin-bottom: 5px;
        margin-right: 10px;
        color: white;
        font-family: 'Roboto';
        cursor: pointer;
    }
    
    #submit-btn.loading {
      background-color: #ccc;
      cursor: not-allowed;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

.break1{
    padding-top:2%;
}

.break2{
    padding-bottom:15%;
}
.bodyProfile {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #A0AEB3;
  color: #fff;

}

.profile {
  background-color: #333;
  padding: 20px;
}
h1 {
  margin: 0;
  font-size: 40;
  color: #fff;

}

main {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 20px;
  padding-bottom: 0;
  grid-template-rows: auto 1fr;
  color: #fff;

}
.text1{
  display: inline-block;
  margin: 2%;
}



.testdrives {
  grid-column: 1 / -1;
  grid-row: 2;
  margin-bottom: 20px;
  color: #fff;
  width: 95%;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;

}

.previous ul,
.current ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #fff;

}

button.edit-profile {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

button.edit-profile:hover {
  background-color: #444;
}

button.change-password {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

button.change-password:hover {
  background-color: #444;
}

button.reschedule:hover {
  background-color: #444;
}

.profile-info {
  margin: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
  grid-column: 1 / -1;
  grid-row: 1;
  color: #fff;
  width: 95%;
}


.dashboard {
  margin: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
  grid-column: 1 / -1;
  grid-row: 1;
  color: #fff;
  width: 95%;
}

@media (max-width: 767px) {
  main {
    display: block;
    padding: 20px;
    color: #fff;
  }
}

.button-wrapper {
  display: flex;
  gap: 10px;
}

.graphs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: -10px;
  padding-left: 100px;
  padding-right: 100px; 
  box-sizing: border-box; 
}
.graphs > div {
  flex: 0 0 calc(50% - 120px); 
  margin: 10px; 
}